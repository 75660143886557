import { ContentInstance } from '@craftercms/studio-ui/models';
import isError from 'lodash/isError';
import { useEffect, useState } from 'react';

import { useCMSContext } from '~/client/context/provider';
import { readContentInstanceUsingSearch } from '~/common/crafter';

export type CrafterContentInstanceResponse<T> = {
  item: T | undefined;
  isLoading: boolean;
  error: Error | null;
};

export const useContentInstance = <T extends ContentInstance = ContentInstance>(
  path: string,
): CrafterContentInstanceResponse<T> => {
  const ctx = useCMSContext();
  const [item, setItem] = useState<T>();
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    if (path) {
      readContentInstanceUsingSearch(ctx, path)
        // eslint-disable-next-line promise/prefer-await-to-then
        .then(instance => {
          if (instance?.craftercms?.contentTypeId) {
            return setItem(instance as T);
          }
          throw new Error('invalid data returned from readContentInstanceUsingSearch');
        })
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch(e => setError(isError(e) ? e : new Error(JSON.stringify(e))));
    }
  }, [ctx, path]);
  return { item, isLoading: !item && !error, error };
};
