import { CMSContext } from '~/types/cms-context';

export const getCMSUrl = (cmsCtx: CMSContext, path: string, queryString?: string): string => {
  if (cmsCtx.isSiteRequired) {
    return (
      cmsCtx.baseUrl +
      path +
      (queryString ? `?${queryString}&crafterSite=${cmsCtx.site}` : `?crafterSite=${cmsCtx.site}`)
    );
  }
  return cmsCtx.baseUrl + path + (queryString ? `?${queryString}` : ``);
};
