import { useRouter } from 'next/router';
import React from 'react';

import FEATURE_FLAGS from '~/constants/FEATURE_FLAGS';
import { FeatureFlagContext } from '~/context/FeatureFlagsHandler/FeatureFlags';
import { ReactNativeFeaturesContext } from '~/context/ReactNativeFeatures';
import { isPathValidRoute } from '~/helpers/path';

const WithSmartAppBanner: React.FC<React.PropsWithChildren> = ({ children }) => {
  const router = useRouter();

  const { featureFlags } = React.useContext(FeatureFlagContext);

  const {
    state: { isReactNative },
  } = React.useContext(ReactNativeFeaturesContext);

  React.useEffect(() => {
    const is404 = !isPathValidRoute(router.asPath);

    const publishSmartBanner = () => {
      if (window.smartbanner !== undefined && !is404 && !router.asPath.includes('/optin/') && !isReactNative && featureFlags?.[FEATURE_FLAGS.WEB_SMARTBANNER]) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        window.smartbanner.publish();
      }
    };

    const startTime = Date.now(); // Define startTime when the effect runs

    // Check if smartbanner is defined every 500 milliseconds until it's defined or 5 seconds have passed
    const interval = setInterval(() => {
      if (window.smartbanner !== undefined && window.document.getElementsByClassName('js_smartbanner').length === 0) {
        publishSmartBanner();
      }

      // Check if banner is already displayed
      if (window.document.getElementsByClassName('js_smartbanner').length > 0) {
        clearInterval(interval); // Clear interval if banner is displayed
      }

      // Check if 5 seconds have passed
      if (Date.now() - startTime > 5000) {
        clearInterval(interval); // Clear interval after 5 seconds
      }
    }, 500);

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [isReactNative, featureFlags?.[FEATURE_FLAGS.WEB_SMARTBANNER], router.asPath, featureFlags]);

  return <>{children}</>;
};

export default WithSmartAppBanner;
