import { WEB_ROUTES, WEB_ROUTES_REGEX } from 'encourage-ecosystem-lib';

export const ROUTES = {
  ...WEB_ROUTES,
  ARTICLE: (articleId: number): string => `/article/${articleId}`,
  CAREER_PROFILE: (slug: string): string => `/explore/my-careers/${slug}`,
  COLLEGE_PROFILE_MY_COLLEGES: (slug: string): string => `/explore/my-colleges/${slug}`,
  COLLEGE_QUIZ: '/explore/my-colleges/quiz',
  COMPLETE_PROFILE: '/completeProfile',
  EXPLORE: '/explore',
  FEEDBACK: '/feedback',
  FULL_SURVEY: '/full-survey',
  GOALS_EDIT: '/goals/edit',
  INTERNAL_SERVER_ERROR: '/internal-server-error',
  MAJOR_PROFILE: (slug: string): string => `/explore/my-majors/${slug}`,
  MANAGE_ACCOUNT: '/profile/settings-dashboard',
  MY_CAREERS: '/explore/my-careers',
  MY_CAREERS_GROUP: '/explore/my-careers/group',
  MY_CAREERS_GROUP_CLUSTER: (code: string) => `/explore/my-careers/group?cluster=${code}`,
  MY_CAREERS_MATCHED: '/explore/my-careers/matched',
  MY_CAREERS_SAVED: '/explore/my-careers/saved',
  MY_COLLEGES: '/explore/my-colleges',
  MY_COLLEGES_GROUP: '/explore/my-colleges/group',
  MY_COLLEGES_INTERESTED: '/explore/my-colleges/interested',
  MY_COLLEGES_MATCHED: '/explore/my-colleges/matched',
  MY_COLLEGES_SAVED: '/explore/my-colleges/saved',
  MY_JOURNEY_11_12: '/my-journey/11-12/',
  MY_JOURNEY_7_10: '/my-journey/7-10/',
  MY_JOURNEY_MAKE_URL: (...slugs: string[]) => `/my-journey/${slugs.join('/')}`,
  MY_MAJORS: '/explore/my-majors',
  MY_MAJORS_GROUP: '/explore/my-majors/group',
  MY_MAJORS_GROUP_AREA: (code: string) => `/explore/my-majors/group?area=${code}`,
  MY_MAJORS_MATCHED: '/explore/my-majors/matched',
  MY_MAJORS_SAVED: '/explore/my-majors/saved',
  OPTIN_CAREER: '/optin/career',
  OPTIN_COLLEGE: '/optin/college',
  OPTIN_MYACT: '/optin/myact',
  PARENT_UNSUBSCRIBE: '/parent-unsubscribe',
  PROFILE_TAB: (slug: string): string => `/profile/${slug}`,
  QUIZ_INTEREST_INVENTORY: '/quiz/interest-inventory',
  RECRUITMENT_OPT_IN: '/profile/settings-dashboard/recruiting-preference',
  SCHOLARSHIP_QUIZ: '/explore/my-scholarships/quiz',
  SCHOLARSHIPS: '/explore/my-scholarships',
  SCHOLARSHIPS_DETAILS: (uuid: string): string => `/explore/my-scholarships/${uuid}`,
  SCHOLARSHIPS_MATCHED: '/explore/my-scholarships/matched',
  SCHOLARSHIPS_SAVED: '/explore/my-scholarships/saved',
  SENTRY: '/sentry',
  SIGNIN: '/signin',
  SIGNOUT: '/signout',
  STYLES: '/styles',
};

export const BASE_64_REGEX = '(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?';
export const ID_REGEX = '[0-9]*';
export const PROFILE_ID_REGEX = '\\d+-\\d+.\\d+'; // matches 12-3456.78
export const COLLEGE_ID_WITH_SLUG_REGEX = '\\b(?!interested|saved|matched|quiz)([a-zA-Z0-9]+)(?:-*\\w+)*'; // matches 12a3456(-and-this-part-is-ignored)
export const MAJOR_ID_REGEX = '\\d+.\\d+'; // matches 12.3456
export const SID_REGEX = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{16}'; // NOT A STANDARD UUID.
export const REDIRECT_TO_HOME_ROUTES = ['/explore/financial-aid/'];

export const ROUTES_REGEX = {
  ...WEB_ROUTES_REGEX,
  ARTICLE: `\\/article\\/${ID_REGEX}`,
  CAREER_PROFILE: `\\/explore\\/my-careers\\/${PROFILE_ID_REGEX}`,
  COLLEGE_PROFILE_MY_COLLEGES: `\\/explore\\/my-colleges\\/${COLLEGE_ID_WITH_SLUG_REGEX}`,
  COLLEGE_QUIZ: '\\/explore\\/my-colleges\\/quiz',
  COMPLETE_PROFILE: '\\/completeProfile',
  EXPLORE_MY_CAREERS: '\\/explore\\/my-careers',
  FULL_SURVEY: '\\/full-survey',
  GOALS_EDIT: '\\/goals\\/edit',
  INTERNAL_SERVER_ERROR: '\\/internal-server-error',
  MAJOR_PROFILE: `\\/explore\\/my-majors\\/${MAJOR_ID_REGEX}`,
  MANAGE_ACCOUNT: '\\/profile\\/settings-dashboard',
  MANAGE_ACCOUNT_TAB: `\\/profile\\/settings-dashboard\\/[a-zA-Z-]*`,
  MY_CAREERS: '\\/explore\\/my-careers',
  MY_CAREERS_GROUP: '\\/explore\\/my-careers\\/group',
  MY_CAREERS_MATCHED: '\\/explore\\/my-careers\\/matched',
  MY_CAREERS_SAVED: '\\/explore\\/my-careers\\/saved',
  MY_COLLEGES: '\\/explore\\/my-colleges',
  MY_COLLEGES_INTERESTED: `\\/explore\\/my-colleges\\/interested`,
  MY_COLLEGES_MATCHED: `\\/explore\\/my-colleges\\/matched`,
  MY_COLLEGES_SAVED: `\\/explore\\/my-colleges\\/saved`,
  MY_JOURNEY_11_12: '\\/my-journey\\/11-12\\/.*',
  MY_JOURNEY_7_10: '\\/my-journey\\/7-10\\/.*',
  MY_MAJORS: '\\/explore\\/my-majors',
  MY_MAJORS_GROUP: '\\/explore\\/my-majors\\/group',
  MY_MAJORS_MATCHED: '\\/explore\\/my-majors\\/matched',
  MY_MAJORS_SAVED: '\\/explore\\/my-majors\\/saved',
  MY_STUFF: '\\/my-stuff\\/.*',
  OPTIN_CAREER: '\\/optin\\/career',
  OPTIN_COLLEGE: '\\/optin\\/college',
  OPTIN_MYACT: '\\/optin\\/myact',
  PARENT_UNSUBSCRIBE: '\\/parent-unsubscribe',
  PROFILE_TAB: `\\/profile\\/[a-zA-Z-]*`,
  QUIZ_INTEREST_INVENTORY: '\\/quiz\\/interest-inventory',
  SCHOLARSHIP_QUIZ: '\\/explore\\/my-scholarships\\/quiz',
  SCHOLARSHIPS: '\\/explore\\/my-scholarships',
  SCHOLARSHIPS_DETAILS: `\\/explore\\/my-scholarships\\/${SID_REGEX}`,
  SCHOLARSHIPS_MATCHED: `\\/explore\\/my-scholarships\\/matched`,
  SCHOLARSHIPS_SAVED: `\\/explore\\/my-scholarships\\/saved`,
  SENTRY: '\\/sentry',
  SIGNIN: '\\/signin',
  SIGNOUT: '\\/signout',
  STYLES: '\\/styles',
};

export default ROUTES;
