import { createTheme } from '@actinc/dls/styles/createTheme';
import { THEME_ENCOURAGE } from '@actinc/dls/styles/themeEncourage';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { linkClasses } from '@mui/material/Link';
import { darken } from '@mui/system';
import isChromatic from 'chromatic/isChromatic';
import deepMerge from 'deepmerge';

import { ITheme, IThemeOptions } from '~/types/theme';

import brandColors from './colors';

import type {} from '@mui/lab/themeAugmentation';

export const overrides: IThemeOptions = {
  ...THEME_ENCOURAGE,
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: () => ({
          opacity: 0,
        }),
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        'aria-label': 'breadcrumb',
      },
      styleOverrides: {
        li: ({ theme }) => ({
          color: (theme as unknown as IThemeOptions).palette.text?.over.light.high,
          [`& .${linkClasses.root}`]: {
            color: (theme as unknown as IThemeOptions).palette.text?.over.light.medium,
          },
          fontWeight: theme.typography.fontWeightMedium,
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: props => {
          if (props.ownerState.color === 'primary') {
            switch (props.ownerState.variant) {
              case 'contained':
                return {
                  backgroundColor: brandColors.sapphire[100],
                };
              default:
                return {};
            }
          }
          return {};
        },
      },
      variants: [
        {
          props: { variant: 'accent' },
          style: ({ theme }) => ({
            '&:disabled': {
              backgroundColor: darken(theme.palette.branding.lightGrey, 0.1),
              pointerEvents: 'unset',
            },
            '&:hover': {
              backgroundColor: darken(theme.palette.branding.lightGrey, 0.1),
            },
            backgroundColor: theme.palette.branding.lightGrey,
            borderRadius: theme.shape.borderRadius * (4 / 3),
            color: theme.palette.branding.navy,
            fontFamily: theme.typography.overline.fontFamily,
            fontSize: theme.typography.body2.fontSize,
            fontWeight: 500,
            textTransform: 'uppercase',
          }),
        },
        {
          props: { variant: 'text' },
          style: () => ({
            '&:hover': {
              backgroundColor: 'unset',
              border: 'unset',
              textDecoration: 'underline',
            },
            border: 'unset',
            minWidth: 0,
            padding: '4px 2px',
          }),
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.branding.mist,
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: props => {
          if (props.ownerState.color === 'primary' || !props.ownerState.color) {
            return {
              color: brandColors.sapphire[100],
            };
          }
          return {};
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: ({ theme }) => ({
          color: theme.palette.branding.teal,
          [`&.${inputLabelClasses.error}`]: {
            color: theme.palette.error.main,
          },
        }),
        root: ({ theme }) => ({
          ...theme.typography.body1,
          color: brandColors.surface.grayscale.neutralGray,
          fontSize: '14px !important',
          fontWeight: '600 !important',
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          paddingBottom: 0,
          paddingTop: 0,
        },
        paper: {
          borderRadius: 4,
          boxShadow:
            '0px 0px 2px 0px #4F5E711F, 0px 2px 4px 0px #4F5E711C, 0px 4px 8px 0px #4F5E711A, 0px 16px 32px 0px #4F5E7117',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          paddingLeft: THEME_ENCOURAGE.spacingPx,
          paddingRight: THEME_ENCOURAGE.spacingPx,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingBottom: THEME_ENCOURAGE.spacingPx * 1.5 - 1,
          paddingLeft: THEME_ENCOURAGE.spacingPx * 3,
          paddingTop: THEME_ENCOURAGE.spacingPx * 1.5,
        },
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: isChromatic() ? false : 'pulse',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: () => ({
          '&.Mui-selected': {
            color: '#00847F',
          },
          height: '100%',
          minHeight: 'unset',
          opacity: 1,
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          '& .MuiTab-root': {
            '&:first-of-type': {
              marginLeft: THEME_ENCOURAGE.spacingPx * 1,
            },
            '&:last-of-type': {
              marginRight: THEME_ENCOURAGE.spacingPx * 1,
            },
          },
          gap: THEME_ENCOURAGE.spacingPx * 2,
          height: '100%',
        },
        indicator: () => ({
          backgroundColor: '#00847F',
          borderRadius: THEME_ENCOURAGE.spacingPx * 1.5,
          height: 3,
          marginBottom: 0,
        }),
        root: () => ({
          minHeight: THEME_ENCOURAGE.spacingPx * 5,
        }),
        scrollButtons: {
          '&.Mui-disabled': {
            display: 'none',
          },
          borderRadius: 0,
        },
      },
    },
  },
  palette: {
    ...THEME_ENCOURAGE.palette,
    // @ts-expect-error: Unreachable code error
    background: {
      default: 'unset',
    },
    branding: {
      ...THEME_ENCOURAGE.palette.branding,
      darkBlue: '#003359',
      darkNavy: '#00223E',
      darkYellow: '#EBDB30',
      grey: '#E0E6EB',
      lightGrey: '#EFF1F3',
      midGrey: '#F2F2F2',
      mist50: '#E4E8EA',
      navy100: '#003057',
      navy70: '#4D6C90',
      seafoam100: '#E3F5F1',
      seafoam50: '#D2EFE9',
      teal100: '#004543',
      tealS1: '#008A86',
    },
  },
  transitions: {
    // eslint-disable-next-line lodash/prefer-constant
    ...(isChromatic() ? { create: () => 'none' } : {}),
  },
  typography: {
    fontWeightLight: 500,
  },
};

export const THEME_OPTIONS: IThemeOptions = deepMerge(THEME_ENCOURAGE, overrides);

export const theme: ITheme = createTheme(THEME_OPTIONS);

export default theme;
