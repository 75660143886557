import MuiLink, { LinkProps } from '@mui/material/Link';
import NextLink from 'next/link';
import React from 'react';

import { isExternalLink } from '~/helpers/links';
import { useOpenUrlWithBridge } from '~/hooks/useOpenUrlWithBridge';
import { useReactNativeObj } from '~/hooks/useReactNativeObj';

export interface ILinkProps extends LinkProps {
  enabled?: boolean;
  externalLink?: boolean;
  linkRef?: React.Ref<HTMLAnchorElement>;
  onClick?: React.MouseEventHandler;
  passHref?: boolean;
  replace?: boolean;
}

const Link: React.FC<ILinkProps> = ({
  children,
  enabled,
  externalLink: externalLinkProp,
  href,
  linkRef,
  onClick,
  passHref = true,
  replace = false,
  ...otherProps
}: ILinkProps): React.ReactElement<unknown> => {
  const reactNativeObj = useReactNativeObj();
  const externalLink = externalLinkProp || isExternalLink(href);
  const sendUrlToBridge = useOpenUrlWithBridge();

  if (!enabled) {
    return <>{children}</>;
  }

  if ((onClick !== undefined && !href) || externalLink) {
    const shouldHandleOverBridge = !!href && externalLink && !!reactNativeObj;
    return (
      <MuiLink
        href={shouldHandleOverBridge ? undefined : href}
        onClick={async (e): Promise<void> => {
          if (onClick) {
            onClick(e);
          }

          if (shouldHandleOverBridge) {
            await sendUrlToBridge(href);
          }
        }}
        {...otherProps}
        ref={linkRef}
        target={shouldHandleOverBridge ? undefined : '_blank'}
      >
        {children}
      </MuiLink>
    );
  }

  return (
    <NextLink href={href ?? ''} legacyBehavior passHref={passHref} replace={replace}>
      <MuiLink onClick={onClick} {...otherProps}>
        {children}
      </MuiLink>
    </NextLink>
  );
};

Link.defaultProps = {
  enabled: true,
  linkRef: undefined,
  onClick: undefined,
  passHref: undefined,
  replace: undefined,
};

export default Link;
