import SessionTimer from '@actinc/dls/components/SessionTimer';
import { useApolloClient } from '@apollo/client';
import { BridgeContext, IBridgeMessageTypes } from 'encourage-ecosystem-lib';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useRouter } from 'next/router';
import React from 'react';

import { RefreshAccessTokenDocument, RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables } from '~/__generated__/graphql';
import { StorageKeys } from '~/constants/localStorage';
import ROUTES from '~/constants/ROUTES';
import { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } from '~/helpers/authTokens';
import localCache from '~/helpers/localCache';
import Logger from '~/helpers/logger';
import useMyProfile from '~/hooks/useMyProfile';
import { useReactNativeObj } from '~/hooks/useReactNativeObj';

const logger = new Logger('RefreshToken');

export const RefreshToken = () => {
  const { actions } = React.useContext(BridgeContext);
  const reactNativeObj = useReactNativeObj();

  const myProfile = useMyProfile();
  const client = useApolloClient();
  const router = useRouter();
  const [refreshedToken, setRefreshedToken] = React.useState(false);

  const onExpire = async (): Promise<void> => {
    logger.debug('Token expired');
    // Redirect user to Sign out page
    await router.push({ pathname: ROUTES.SIGNOUT });
  };

  const refreshAllTokens = async () => {
    const refreshToken = getRefreshToken();
    // If we don't have either the refresh token, or there was some problem initializing the Apollo client, expire the session
    if (!refreshToken || !client) {
      await onExpire();
      return;
    }

    // GQL mutation to fetch a new token
    const { data } = await client.mutate<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>({
      fetchPolicy: 'network-only',
      mutation: RefreshAccessTokenDocument,
      variables: { refreshToken },
    });

    // If the refresh was not successfull, expire session.
    if (!data || data?.encourageUserRefreshUserAccessToken?.__typename !== 'EncourageUserRefreshUserAccessTokenSuccess') {
      await onExpire();
      return;
    }

    const { accessToken: newAccessToken, refreshToken: newRefreshToken } = data.encourageUserRefreshUserAccessToken;

    // If mutation returned sucess, but it doesn't have new tokens, expire session.
    if (!newAccessToken || !newRefreshToken) {
      await onExpire();
      return;
    }

    // If no errors ocurred, set new tokens
    setAccessToken(newAccessToken);
    setRefreshToken(newRefreshToken);

    // Send a bridge message to React Native to update the tokens.
    if (reactNativeObj) {
      await actions.sendMessage<IBridgeMessageTypes.FROM_WEB_REFRESH_TOKENS>(
        IBridgeMessageTypes.FROM_WEB_REFRESH_TOKENS,
        {
          accessToken: newAccessToken,
          refreshToken: newRefreshToken,
        },
        {
          redactPaths: ['accessToken', 'refreshToken'],
        },
      );
    }

    // This setRefreshedToken is meant to trigger the useMemo below and force the decoded to be re-fetched from local storage
    // the re-mounted SessionTimer, with the updated jwt token, is what handles the prompt display and session expire
    setRefreshedToken(!refreshedToken);
  };

  const accessToken = getAccessToken();

  return React.useMemo(() => {
    // My Profile API doesn't return 'exp' and 'iat' values, we'll need to do this differently
    if (!accessToken || !myProfile) {
      return null;
    }

    // Decode the JWT from the local cache
    const decoded = jwtDecode<JwtPayload>(localCache.get<string>(StorageKeys.ACCESS_TOKEN) ?? '');
    // exp is the time in ms until the token expires, while iat is when the token was validated
    const exp = decoded.exp;
    const iat = decoded.iat;

    if (exp && iat) {
      // This is the timer to expire the session (you can lower the time and test it locally by using 'new Date((iat + 300) * 1000)' to test the timeout).
      // Note: there is a bug for short expire times, the 'Continue session' dialog will not be rendered if the timer is too short, setting to 5 min fixes it.
      const expiresAt = new Date(exp * 1000);
      const tokenMaxAgeMs = (exp - iat) * 1000; // usually 1 hour
      // This shows a prompt dialog to confirm continue or not current session, clicking continue renews the token and re-mounts the SessionTimer
      const promptWithMsRemaining = tokenMaxAgeMs / 12; // usually 5 minutes

      logger.debug('Re-rendering session timer: ', { expiresAt, promptWithMsRemaining, tokenMaxAgeMs });

      return (
        <SessionTimer
          expiresAt={expiresAt}
          onExpire={onExpire}
          onKeepAlive={refreshAllTokens}
          promptWithMsRemaining={promptWithMsRemaining}
          tokenMaxAgeMs={tokenMaxAgeMs}
        />
      );
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAllTokens, refreshedToken, accessToken]);
};

RefreshToken.defaultProps = {
  onExpire: undefined,
};

export default RefreshToken;
