import { Stack } from '@mui/material';
import { MediaProviderAdapter, PlayerSrc, isHLSProvider } from '@vidstack/react';
import { useCallback, useEffect, useState } from 'react';

import { useCMSContext } from '~/client/context';
import { getCMSUrl } from '~/client/utils';
import { CrafterVideo } from '~/types/models/video';

import { VideoView } from './VideoView';

export type VideoProps = {
  model: CrafterVideo;
};

export const Video = ({ model }: VideoProps): React.ReactNode => {
  const cmsContext = useCMSContext();
  const [src, setSrc] = useState<PlayerSrc>({} as PlayerSrc);

  const { thumbnail_s: poster, description_t: description } = model;

  useEffect(() => {
    const videoSrc = model?.video_o[0]?.url;
    if (videoSrc) {
      setSrc({
        src: getCMSUrl(cmsContext, videoSrc),
        type: 'application/x-mpegurl',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsContext, model?.video_o]);

  const onProviderChange = useCallback(
    (provider: MediaProviderAdapter | null): void => {
      if (isHLSProvider(provider)) {
        // eslint-disable-next-line no-param-reassign
        provider.config = {
          /**
           * Video fragments on HLS are not loading correctly due to the url query being lost in the process.
           * We need to pass the correct URL again using getCMSUrl
           */
          xhrSetup(xhr, url): void {
            const { pathname } = new URL(url);
            xhr.open('GET', getCMSUrl(cmsContext, pathname));
          },
        };
      }
    },
    [cmsContext],
  );

  const rawCaptionsSrc = model.captions_o?.length ? model.captions_o[0]?.value ?? model.captions_o[0]?.craftercms?.path : null;

  const captionSrc = rawCaptionsSrc ? getCMSUrl(cmsContext, rawCaptionsSrc) : null;
  const posterSrc = getCMSUrl(cmsContext, poster);
  return (
    <Stack gap={0.5}>
      <VideoView captionsSrc={captionSrc} description={description} model={model} onProviderChange={onProviderChange} posterSrc={posterSrc} videoSrc={src} />
    </Stack>
  );
};
