import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';
import React from 'react';

import Link from '~/components/Link';
import ROUTES from '~/constants/ROUTES';
import { useReactNativeObj } from '~/hooks/useReactNativeObj';
import LogoSvg from '~/public/static/img/logo.svg';

import { LeftSplashGrid, LeftSplashAnimation, ParentSplashGrid, ContentFrame, ContentArea, ContentBox, Footer } from './styles';

interface IProps {
  children: React.ReactNode;
  heading: React.ReactNode;
}

const currentYear = new Date().getFullYear();

const SplashLayout: React.FC<IProps> = ({ children, heading }) => {
  const router = useRouter();
  const reactNativeObj = useReactNativeObj();
  const logoLink = !!reactNativeObj || router.route === ROUTES.SIGNIN ? undefined : ROUTES.SIGNIN;

  return (
    <ParentSplashGrid container direction="row" spacing={0}>
      <LeftSplashGrid item>
        <LeftSplashAnimation loop={false} rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }} />
      </LeftSplashGrid>
      <Grid
        item
        md
        sm={12}
        sx={{
          height: '100vh',
          overflow: 'scroll',
          paddingBottom: `${reactNativeObj?.safeAreaInsets.bottom || 0}px`,
          paddingTop: `${reactNativeObj?.safeAreaInsets.top || 0}px`,
          width: '100%',
        }}
      >
        <ContentFrame disableGutters maxWidth={false}>
          <Grid container direction="column" sx={{ height: '100%' }} wrap="nowrap">
            <Grid item sx={{ paddingLeft: 1.5 }}>
              {logoLink ? (
                <Link href={logoLink}>
                  <LogoSvg />
                </Link>
              ) : (
                <LogoSvg />
              )}
            </Grid>
            <ContentArea item>
              <ContentBox>{heading}</ContentBox>
              <ContentBox>{children}</ContentBox>
            </ContentArea>
            <Grid item>
              <Footer>
                <Typography variant="caption">© My College Options Inc. {currentYear}</Typography>
              </Footer>
            </Grid>
          </Grid>
        </ContentFrame>
      </Grid>
    </ParentSplashGrid>
  );
};

SplashLayout.defaultProps = {};

export default SplashLayout;
